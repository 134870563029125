import { useEffect, useState } from "react";
import api, { apiUrl } from "../api";
import AddAndEditTable from "../Components/AddAndEditTable";
const Campaign = () => {
  const [companies, setCompanies] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  useEffect(() => {
    getCampaignList();
    getCompanyList();
    getCompanyCategoryList();
  }, []);
  const getCampaignList = async () => {
    const campaigns = await api.admin.campaign.get();
    !campaigns.error &&
      setCampaigns(
        campaigns.map((item) => ({
          ...item,
          LogoImageUrl: apiUrl + "/static/campaignImages/" + item.Id + ".jpg",
        }))
      );
  };
  const getCompanyList = async () => {
    const companies = await api.admin.company.get();
    !companies.error &&
      setCompanies(
        companies.map((item) => ({
          ...item,
        }))
      );
  };
  const getCompanyCategoryList = async () => {
    const companyCategories = await api.admin.companyCategory.get();
    !companyCategories.error && setCompanyCategories(companyCategories);
  };
  const createCampaign = async (row) => {
    const create = await api.admin.campaign.create(row);
    if (!create.error) {
      getCampaignList();
      return alert("Kampanya oluşturuldu.");
    }
    alert(
      "Kampanya oluşturulamadı.(Hatalar :" +
        create.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const updateCampaign = async (id, row) => {
    const update = await api.admin.campaign.update(id, row);
    if (!update.error) {
      getCampaignList();
      return alert("Kampanya Güncellendi.");
    }
    alert(
      "Kampanya Güncellenemedi. (Hatalar :" +
        update.errors?.map((item) => item.msg).join(",") +
        ")"
    );
  };
  const deleteCampaign = async (id) => {
    const confirmm = window.confirm("Silmek istediğinize emin misiniz?");
    if (confirmm) {
      const del = await api.admin.campaign.delete(id);
      if (!del.error) {
        getCampaignList();
        return alert("Kampanya Silindi.");
      }
      alert(
        "Kampanya Silinemedi.(Hatalar :" +
          del.errors?.map((item) => item.msg).join(",") +
          ")"
      );
    }
  };
  return (
    <AddAndEditTable
      type="Kampanya"
      rows={campaigns}
      cols={[
        {
          type: "select",
          label: "Firma",
          value: "CompanyId",
          name: "CompanyId",
          values: companies.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
          noShow: true,
        },
        {
          type: "select",
          label: "Firma Kategorisi",
          value: "CategoryId",
          name: "CategoryId",
          values: companyCategories.map((item) => ({
            ...item,
            label: item.Name,
            id: item.Id,
          })),
        },
        {
          name: "Title",
          type: "text",
          label: "Başlık",
        },
        {
          name: "CompanyName",
          type: "text",
          label: "Şirket",
          noShowForm: true,
          noShowEdit: true,
        },
        {
          name: "Percent",
          type: "number",
          label: "Yüzde oranı",
        },
        {
          name: "LogoImageUrl",
          type: "file",
          label: "Kampanya Resmi",
          fileType: "image",
          width: "40px",
          height: "40px",
        },
      ]}
      create={(row) => createCampaign(row)}
      update={(id, row) => updateCampaign(id, row)}
      deletee={(id) => deleteCampaign(id)}
    />
  );
};
export default Campaign;
